import { getQueryParam } from "../utils/route";

const apiConstants = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Auth-Key": process.env.REACT_APP_BUILD_PROFILE === "local" ? process.env.REACT_APP_TEST_AUTH_KEY : getQueryParam("auth") ?? "",
    "X-Platform": process.env.REACT_APP_BUILD_PROFILE === "local" ? "tdesktop" : window?.Telegram?.WebApp?.platform || "unknown",
  },
};

export default apiConstants;

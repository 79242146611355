import { type ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Engine, Render, Runner, World } from "matter-js";

import { usePopup } from "../../../../../components/Popup/hook/usePopup";
import { useScreen } from "../../../../hooks/screen/useScreen";
import useGameArea from "../../hooks/useGameArea";
import { useStaticFigureSkater } from "../../objects/StaticFigureSkater";
import { usePlayerAsViewer } from "../../objects/PlayerAsViewer";
import { useSpotlight } from "../../objects/Spotlight";
import { useSnowflake } from "../../objects/Snowflake";
import NotAccessGamePopup from "../../../../../popups/NotAccessGamePopup";
import GameInstructionPopup from "../../../../../popups/GameInstructionPopup";
import GameLayout from "../../../../../components/GameLayout";
import StartButton from "./components/StartButton";
import Tribune from "../../components/Tribune";

import styles from "./styles.module.scss";
import PopupContent from "../../../../../components/Popup/components/PopupContent";
import CurrentCity from "../../../../../components/CurrentCity";

interface StartScreenProps {
  setScreen: () => void;
  isActive: boolean | undefined;
  attemptsExists: boolean | undefined;
}

export default function StartScreen({ setScreen, isActive, attemptsExists }: StartScreenProps): ReactElement {
  const { showPopup } = usePopup();
  const { screenWidth, screenHeight } = useScreen();
  const { startRespawnSnowflake, stopRespawnSnowflake } = useGameArea();

  const { getStaticFigureSkater } = useStaticFigureSkater();
  const { getPlayerAsViewer } = usePlayerAsViewer();
  const { getSpotlight } = useSpotlight();
  const { getSnowflakes, respawnSnowflake } = useSnowflake();

  const sceneRef = useRef<HTMLCanvasElement>(null);
  const renderRef = useRef<Render | null>(null);
  const engineRef = useRef<Engine>(
    Engine.create({
      gravity: {
        x: 0,
        y: 0.275,
      },
    })
  );

  const watchRespawnSnowflake = useCallback(() => {
    if (!engineRef.current) return;

    respawnSnowflake(engineRef.current.world, screenWidth);
  }, [respawnSnowflake, screenWidth]);

  useEffect(() => {
    const engine = engineRef.current;
    const canvas = sceneRef.current;

    if (!canvas) {
      return;
    }

    renderRef.current = Render.create({
      canvas,
      engine,
      options: {
        hasBounds: true,
        background: "transparent",
        width: screenWidth,
        height: screenHeight,
        pixelRatio: window.devicePixelRatio,
        wireframes: false,
        // showPerformance: true
      },
    });

    const runner = Runner.create({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      maxFrameTime: 1000 / 50,
      delta: 1000 / (60 * 10),
    });

    const playerAsViewer = getPlayerAsViewer();
    const staticFigureSkater = getStaticFigureSkater();
    const snowflakes = getSnowflakes(screenWidth, 4);
    const spotlight = getSpotlight({
      x: screenWidth / 2 + 100,
      y: screenHeight - 10,
      width: 172,
      height: screenHeight - 10,
    });

    World.add(engineRef.current.world, [spotlight, playerAsViewer, staticFigureSkater, snowflakes]);

    Runner.run(runner, engineRef.current);
    Render.run(renderRef.current);

    startRespawnSnowflake(engineRef.current, watchRespawnSnowflake);

    const engineTmp = engineRef.current;
    const renderTmp = renderRef.current;

    return () => {
      stopRespawnSnowflake(engineTmp, watchRespawnSnowflake);

      Render.stop(renderTmp);
      renderTmp.canvas.remove();

      Runner.stop(runner);
      World.clear(engineTmp.world, false);
      Engine.clear(engineTmp);
    };
  }, [
    getSpotlight,
    getSnowflakes,
    screenHeight,
    screenWidth,
    startRespawnSnowflake,
    stopRespawnSnowflake,
    watchRespawnSnowflake,
    getPlayerAsViewer,
    getStaticFigureSkater,
  ]);

  const [shownNotAccessGamePopup, setShownNotAccessGamePopup] = useState(false);
  const [shownGameInstructionPopup, setShownGameInstructionPopup] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setShownNotAccessGamePopup(true);
    } else if (attemptsExists) {
      setShownGameInstructionPopup(true);
    }
  }, [attemptsExists, isActive, showPopup]);

  return (
    <>
      <CurrentCity />
      <GameLayout>
        <canvas
          ref={sceneRef}
          className={styles.StartScreen__canvas}
          width={screenWidth}
          height={screenHeight}
          style={{ width: screenWidth, height: screenHeight }}
        />
        <Tribune width={[0, screenWidth]} isScaled={true} />
        <StartButton onClick={setScreen} disabled={!isActive} />
      </GameLayout>
      <PopupContent
        shown={shownNotAccessGamePopup}
        id="NotAccessGamePopup"
        customPosition={{ y: 242, x: 65 }}
        background={false}
        color="yellow"
        variant="message"
      >
        <NotAccessGamePopup />
      </PopupContent>
      <PopupContent
        shown={shownGameInstructionPopup}
        id="GameInstructionPopup"
        customPosition={{ y: 242, x: 65 }}
        background={false}
        color="yellow"
        variant="message"
      >
        <GameInstructionPopup />
      </PopupContent>
    </>
  );
}

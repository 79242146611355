import { createContext, type PropsWithChildren, type ReactElement, useState } from "react";
import { type City } from "..";

export interface CitiesPopupContextProps {
  cityRequired: boolean;
  city: City | null;
  setCityRequired: (isActive: boolean) => void;
  setCity: (name: City | null) => void;
}

export const CitiesPopupContext = createContext<CitiesPopupContextProps>({
  cityRequired: false,
  city: null,
  setCityRequired: () => {},
  setCity: () => {},
});

export function CitiesPopupProvider({ children }: PropsWithChildren): ReactElement {
  const [cityRequired, setCityRequired] = useState<boolean>(false);
  const [city, setCity] = useState<City | null>(null);

  return (
    <CitiesPopupContext.Provider value={{ cityRequired, city, setCityRequired, setCity }}>
      {children}
    </CitiesPopupContext.Provider>
  );
}

import { createContext, type PropsWithChildren, type ReactElement, type ReactNode, useCallback, useState } from "react";

import PopupContent, { type PopupContentColor, type PopupContentVariant } from "./components/PopupContent";
import InfoPopup, { type InfoPopupProps } from "../../popups/InfoPopup";

interface ShownPopupConfig {
  id: string;
  content: ReactNode;
  customPosition?: { x?: number; y?: number };
  background?: boolean;
  color?: PopupContentColor;
  variant?: PopupContentVariant;
  exitButton?: boolean;
  closeButton?: boolean;
  notTouch?: boolean;
}

export interface PopupContextProps {
  showPopup: (config: ShownPopupConfig) => void;
  showInfoPopup: (data?: InfoPopupProps) => void;
  hidePopup: () => void;
}

export const PopupContext = createContext<PopupContextProps>({
  showPopup: () => {
  },
  hidePopup: () => {
  },
  showInfoPopup: () => {
  },
});

export function PopupProvider({ children }: PropsWithChildren): ReactElement {
  const [popupContent, setPopupContent] = useState<ShownPopupConfig | null>(null);

  const { id, content, ...restPopupProps } = popupContent ?? {};

  const hidePopup = useCallback(() => {
    setPopupContent(null);
  }, []);

  const showPopup = useCallback((config: ShownPopupConfig) => {
    setPopupContent(config);
  }, []);

  const showInfoPopup = useCallback((data?: InfoPopupProps) => {
    setPopupContent({
      id: "InfoPopup",
      content: (
        <InfoPopup {...data} />
      ),
      variant: "popup",
      color: "white",
      background: true,
      exitButton: true,
    });
  }, []);

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup, showInfoPopup }}>
      {children}
      <PopupContent shown={!!id} id={id} hidePopup={hidePopup} {...restPopupProps}>{content}</PopupContent>
    </PopupContext.Provider>
  );
}

import { type ReactElement } from "react";

import styles from "./styles.module.scss";

export type Message = "AUTHENTICATION_ERROR" | "ERROR" | "GAME_OVER" | "FORBIDDEN" | "CITY_REQUIRED_BOT_NOT_PRESENT";

export interface InfoPopupProps {
  message?: Message | undefined;
}

const infoMapping: Record<Message, string> = {
  AUTHENTICATION_ERROR: "Ошибка авторизации",
  ERROR: "Повтори позже",
  GAME_OVER: "Игра окончена",
  FORBIDDEN: "К сожалению, с твоего устройства нельзя пройти игру",
  CITY_REQUIRED_BOT_NOT_PRESENT: "Для начала игры необходимо выбрать город",
};

export default function InfoPopup({ message = "ERROR" }: InfoPopupProps): ReactElement {
  return (
    <div className={styles.InfoPopup}>
      <b className={styles.InfoPopup__title}>Ошибка</b>
      <p>{infoMapping[message]}</p>
    </div>
  );
}
